.skills-bg-color {
    background-image: url(../images/services/bg-slill.jpg);
}

.skill-inner {
    .thumbnail {
        img {
            width: 100%;
        }
    }
    
}