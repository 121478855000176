


.tmp-mesonary-area-main{
    .tmp-button-group{
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
        button{
            max-width: max-content;
            display: block;
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            color: #292929;
            border-radius: 3px;
            background: #fff;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
            &.is-checked{
                background: var(--color-primary);
                border: none;
                color: #fff;
            }
        }
    }
}




.tmp-single-portfolio-item{
    padding: 32px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    @media #{$md-layout} {
        padding: 20px;
    }
    @media #{$large-mobile} {
        padding: 15px;
    }
    .thumbnail{
        overflow: hidden;
        display: block;
        border-radius: 12px;
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
        img{
            width: 100%;
            transition: .3s;
            border-radius: 12px;
        }
    }
    .tmp-inner-content{
        .top-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 19px;
            margin-bottom: 15px;
            .left{
                color: #292929;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
            }
            .right{
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }
        a .title{
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0;
            transition: .3s;
            br{
                display: none;
            }
            @media #{$md-layout} {
                font-size: 18px;
            }
            @media #{$large-mobile} {
                font-size: 18px;
            }
            &:hover{
                color: var(--color-primary);
            }
        }
    }
  }