#side-hide{
    position: fixed;
    right: -400px;
    height: 100vh;
    z-index: 1000;
    box-shadow: 0 15px 40px -5px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    width: 400px;
    background: #fff;
    transition: .5s;
    @media #{$large-mobile} {
        width: 320px;
    }
    .top-area{
        padding: 30px 20px;
        background: #1d1e33;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo-area{
            img{
                max-width: 120px;
            }
        }
        .close-icon-area{
            #close-slide__main{
                cursor: pointer;
                height: 45px;
                width: 45px;
                border-radius: 50%;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                i{
                    color: var(--color-primary);
                }
            }
        }
    }
    .body{
        padding: 30px;
    }
    &.show{
        right: 0;
    }
}




#overlay_every-where{
    // cursor: url(../images/banner/shape/close.png), auto;
    background: #0e1013;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease-in-out;
    pointer-events: none;
    z-index: 50;
}


#overlay_every-where.bgshow {
    background: #0e1013;
    opacity: 70%;
    visibility: visible;
    pointer-events: visible;
    z-index: 999;
    top: 0;
}



.short-contact-area-side-collups{
    .single-contact-information-side{
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        i{
            height: 45px;
            width: 45px;
            background: var(--color-primary);
            display: flex;
            align-items: center;
            color: #fff;
            justify-content: center;
            font-size: 18px;
            border-radius: 5px;
        }
        .information{
            span{
                display: block;
                color: var(--color-primary);
                text-transform: uppercase;
                font-size: 15px;
                margin-bottom: 0;
            }
        }
    }
}

#side-hide{
    &::-webkit-scrollbar{
        display: none;
    }
    .body{
        position: relative;
        .title{
            font-size: 26px;
            text-transform: capitalize;
            line-height: 1.5;
            @media #{$large-mobile} {
                font-size: 22px;
            }
        }
        p.disc{
            font-size: 16px;
        }
        .social-icons{
            margin-top: 100px;
            li{
                a{
                    &:hover{
                        background: var(--color-primary);
                    }
                }
            }
        }
    }
}