.header-top-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
}
.section-head {
    text-align: center;
    padding-bottom: 60px;

    &.pb-40 {
        padding-bottom: 40px;
    }
    &.pb-0 {
        padding-bottom: 0;
    }

    &.text-align-left {
        text-align: left;
    }

    &.section-head-one-side {
        padding-bottom: 22px;
    }
    .section-sub-title {
        font-size: 16px;
        font-weight: var(--s-bold);
        color: var(--color-primary);
        text-transform: uppercase;
        margin-bottom: 5px;
        font-family: var(--font-secondary);
        display: flex;
        align-items: center;
        gap: 7px;
        &.color-secondary {
            color: var(--color-secondary);
        }

        &.color-white {
            color: var(--color-white);
        }
        img {
            margin-right: 8px;
        }
        span{
            &.subtitle {
                font-family: var(--font-secondary);
            }
        }
        
    }
    .title {
        color: #03041c;
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 0;
        font-weight: var(--s-extra-bold);
        margin-bottom: 0 !important;
        font-family: var(--font-secondary);
        @media #{$sm-layout} {
            font-size: 32px;
            line-height: 1.4;
            br{
                display: none;
            }
        }
        @media #{$large-mobile} {
            font-size: 28px;
            line-height: 1.4;
            br{
                display: none;
            }
        }
        @media #{$small-mobile} {
            font-size: 22px;
        }
        &.color-white {
            color: var(--color-white);
        }
    }

    .description {
        margin-top: 10px;
    }


}

.section-head.color-white {
    text-align: center;
    padding-bottom: 60px;
    .section-sub-title {
        font-size: 16px;
        font-weight: var(--s-bold);
        color: var(--color-primary);
        text-transform: uppercase;
        span.subtitle {
            margin-left: 8px;
            font-family: var(--font-secondary);
        }
    }
    .title{
        color: #ffffff;
        font-size: 36px;
        line-height: 45px;
        @media #{$large-mobile} {
            font-size: 22px;
            line-height: 34px;
        }
    }
}


.section-head {
    &.pb--30 {
        padding-bottom: 30px;
    }
    .subtitle {
        font-weight: var(--s-bold);
        &.color-gradiant {
            background: var(--text-gradient-one);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}


.section-head{
    .section-sub-title{
        &.center-title{
            justify-content: center;
        }
    }
}

