.vedio-icone{
    .play-video{
        p.text{
            min-width: max-content;
            margin-top: 10px;
            margin-left: 55px;
            color: #fff;
        }
    }
    .video-play-button {
        position: relative;
        z-index: 2;
        box-sizing: content-box;
        display: block;
        width: 32px;
        height: 44px;
        border-radius: 50%;
        padding: 18px 20px 18px 28px;
        display: flex;
        &::before{
            content: "";
            position: absolute;
            z-index: 0;
            left: -32%;
            top: -31%;
            display: block;
            width: 130px;
            height: 130px;
            background: rgba(117, 39, 8, 0.3);
            border-radius: 50%;
            border: 1px solid #ffffff00;
            animation: waves 3s ease-in-out infinite;
        }
        &::after{
            content: "";
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 70px;
            height: 70px;
            background: #fff;
            border: 2px solid #fff;
            transition: all 200ms;
            border-radius: 50%;
            @media #{$large-mobile} {
                width: 60px;
                height: 60px;
            }
        }
        span{
            display: block;
            position: relative;
            z-index: 3;
            width: 0;
            height: 0;
            border-left: 15px solid var(--color-primary);
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 47%;
            &.outer-text{
                border: none;
                min-width: max-content;
                margin-left: 75px;
                position: relative;
                margin-top: -12px;
                color: var(--color-primary);
                font-weight: 500;
            }
        }
    }
    .video-overlay {
        position: fixed;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000000eb;
        opacity: 0;
        transition: all ease 500ms;
        display: none;
        iframe{
            width: 70%;
            height: 70%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            top: 50%;
            position: relative;
            transform: translateY(-50%);
        }
        &.open{
            position: fixed;
            z-index: 1000;
            opacity: 1;
            display: block;
        }
        .video-overlay-close {
            position: absolute;
            z-index: 1000;
            top: 15px;
            right: 20px;
            font-size: 36px;
            line-height: 1;
            font-weight: 400;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            transition: all 200ms;
        }
    }
}


@keyframes waves {
    0% {
      -webkit-transform: scale(0.2, 0.2);
      transform: scale(0.2, 0.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
      opacity: 0.9;
    }
}     



.large-video-playing{
    video{
        width: 100%;
    }
}

.grow-thumbnail {
    transform: scale(0.9);
}