.team-details-thumb {
    text-align: center;
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: " ";
        background-color: var(--color-white);
        height: 90%;
        width: 100%;
        top: 80px;
        left: 0;
        box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.055);
        z-index: -1;
    }


    .thumbnail {
       
        img {
            max-width: max-content;
            @media #{$smlg-device} {
                max-width: 100%;
            }
            
        }
    }

    .content {
        padding: 0 30px;
        padding-bottom: 40px;
        padding-top: 30px;
        .author-info {
            .name {
                font-size: 27px;
                line-height: 40px;
                font-family: var(--font-secondary);
                margin-bottom: 10px;
            }

            .designation {
                line-height: 40px;
                display: inline-block;
                margin-bottom: 10px;
            }

        }

        .description {
            margin-bottom: 20px;
        }

        .team-details-social {
            display: flex;
            align-items: center;
            justify-content: center;

            .social-icons {
                li {
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}


.team-details-content {
    padding-left: 60px;
    @media #{$md-layout} {
        padding-left: 15px;
        margin-top: 50px;
    }
    @media #{$sm-layout} {
        padding-left: 15px;
        margin-top: 50px;
    }
    .personal-info {
        padding-bottom: 30px;
        border-bottom: 1px solid #E7E7E7;

        .title {

        }

        .description {
            margin-bottom: 15px;
        }
        .all-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media #{$sm-layout} {
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
            }
            .info-left,
            .info-right {
                .single-info {
                    font-size: 16px;
                    line-height: 28px;
                    font-family: var(--font-secondary);
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        color: var(--color-heading);
                        font-weight: var(--s-bold);
                    }
                    
                }
            }
        }
    }


    .personal-experience {
        padding-top: 30px;
        .description {
            margin-bottom: 0;
        }

        .progress-wrapper {
            margin-top: 30px;
        }
    }
}


.bg-image-none{
    background-image: none !important;
}