// mobile menu active

#side-hide{
    .body{
        display: block;
        margin-top: 20;
        @media #{$md-layout} {
            display: none;
        }
        @media #{$sm-layout} {
            display: none;
        }
    }
    .mobile-menu-main{
        display: none;
        @media #{$md-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: block;
        }
    }
}


// mobile menu scss

.mobile-menu-main nav ul li a {
    display: block;
    color: #181010;
}

.mobile-menu-main{
    nav{
        ul{
            padding: 0 20px;
            display: block;
            li{
                margin: 0;
                padding: 0;
                a.main{
                    padding: 12px 0 17px 0;
                    border-bottom: 1px solid #f3f3f3;
                    cursor: pointer;
                }
                &.has-droupdown{
                    position: relative;
                    ul{
                        padding: 0;
                        a{
                            padding: 10px 0;
                        }
                        a.tag{
                            font-weight: 700;
                            margin-top: 15px;
                            font-size: 18px;
                            border-bottom: 2px solid var(--color-primary);
                            padding: 10px 0;
                        }
                        li{
                            margin: 7px 0 !important;
                            border-bottom: 1px solid #f3f3f3;
                        }
                    }
                    &::after{
                        position: absolute;
                        content: '\f078';
                        font-family: 'Font Awesome 6 pro' !important;
                        font-size: 16px;
                        right: 0;
                        font-weight: 400;
                        top: 5px;
                        padding: 8px 13px;
                        color: #fff;
                        background: var(--color-primary) !important;
                        pointer-events: none;
                        cursor: pointer;
                    }
                    &.mm-active{
                        &::after{
                            content: '\f077';
                        }
                    }
                    &.third-lvl{
                        &::after{
                            font-size: 10px;
                            padding: 3px 10px;
                        }
                        ul{
                            padding: 0 20px;
                            li{
                                padding: 10px 0 !important;
                                position: relative;
                                z-index: 1;
                                transition: all .3s;
                                &:hover{
                                        color: var(--color-primary);
                                }
                                a{
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    transition: all .3s;
                                }
                                &:last-child{
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
                a{
                    display: block;
                }
            }
        }
    }
    .social-wrapper-one{
        margin-top: 50px;
    }
}



.sub-dropdown{
    position: relative !important;
    display: block !important;
    .submenu.third-lvl{
        opacity: 0 !important;
        min-width: 185px !important;
        left: 100% !important;
        top: -13% !important;
        margin: 0;
        border-radius: 0 !important;
        &.base{
            display: none !important;
        }
    }
    &:hover{
        .sub-menu-link{
            color: var(--color-primary);
        }
        .submenu.third-lvl.base{
            opacity: 1!important;
            min-width: 185px !important;
            top: 0 !important;
            right: 3px;
            display: block !important;
            li{
                display: block;
                a{
                    display: block !important;
                }
            }
        }
    }
}

.header-three{
    .sub-dropdown:hover .submenu.third-lvl.base{
        margin-left: -14px !important;
    }
}
.header-two .header-main-wrapper{
    .sub-dropdown:hover .submenu.third-lvl.base{
        margin-left: 90px !important;
    }
}



.mobile-menu-main{
    .rts-social-style-one{
        ul{
            padding-left: 0;
            display: flex;
            align-items: center;
            list-style: none;
            gap: 15px;
            li{
                margin: 0;
                a{
                    height: 45px;
                    width: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 1px solid #f3f3f3;
                    transition: .3s;
                }
                &:hover{
                    a{
                        background: var(--color-primary);
                        color: #fff;
                        border-color: var(--color-primary);
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }
}


.onepage{
    .mobile-menu-main nav ul li.has-droupdown::after{
        display: none;
    }
}







