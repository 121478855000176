.tmp-tag-list {
    &.tag-list-one {
        .tag-list {
            border: 1px solid #efefef;
            border-radius: 8px;
            padding: 1px 5px;
            transition: 0.5s;
            line-height: 24px;
            padding: 5px 8px;
            @media #{$smlg-device} {
                margin-right: 3px;
            }
            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: var(--color-white);
                background: var(--color-secondary);
                border: 1px solid var(--color-white);
            }
        }
    }
}

.contact-area-three.agency{
    .tmp-tag-list {
        &.tag-list-one {
            .tag-list {
    
                &:hover {
                    color: var(--color-white);
                    background: var(--color-primary);
                    border: 1px solid var(--color-primary);
                }
            }
        }
    }
}