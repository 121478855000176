
// social style
.social-area-transparent{
    display: flex;
    align-items: center;
    span{
        color: #fff;
        font-weight: 400;
    }
    a{
        margin-left: 18px;
        i{
            color: #fff;
        }
    }
}


.social-icons {
    padding: 0;
    margin: 0;
    li {
        list-style: none;
        margin: 0;
        transition: .5s;
        a {
            display: inline-block;
        }
    }
    

    &.social-icons-with-bg {
        background-color: var(--color-white);
        position: absolute;
        right: 0;
        bottom: 100%;
        transition: .5s;
        transform: scale(1, 0);
        transform-origin: bottom left;

        li {
            a {
                width: 60px;
                height: 60px;
                transition: .5s;
                display: flex;
                align-items: center;
                justify-content: center;
               
                i {
                    color: var(--color-heading);
                    transition: .5s;
                }
                
            }
            &:hover {
                background-color: var(--color-primary);
               i {
                   color: var(--color-white);
                 }
                
             }
        }
    }

    &.solid-social-icons {
        display: flex;
        li {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            a {
                i {
                    color: red;
                }
            }
        }

    }

    &.rounded-social-icons {
        li {
            a {
                height: 40px;
                width: 40px;
                line-height: 40px;
                border-radius: 5px;
                background-color: #1D1E33;
                text-align: center;
                transform: scale(1);
                transition: .5s;
                i {
                    color: #ffffff;
                }
                &:hover {
                    transform: scale(1.1);
                    background-color: var(--color-primary);
                }
                &.border-one-primary {
                    border: 1px solid var(--color-primary);
                    i {
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}

