// blog Details area

.blog-details-left-area{
    .thumbnail-top{
        img{
            width: 100%;
        }
    }
    .blog-details-discription{
        padding: 30px 0;
        .category-area{
            margin-bottom: 18px;
            color: #03041C;
            font-weight: 500;
        }
        h3.title{
            font-size: 36px;
            line-height: 45px;
            @media #{$large-mobile} {
                font-size: 26px;
                line-height: 1.5;
            }
        }
        p.disc{
            margin-bottom: 20px;
            font-size: 16px;
        }
    }
    .quote-area-blog-details{
        background: #03041C;
        padding: 60px 50px;
        display: block;
        z-index: 1;
        position: relative;
        @media #{$sm-layout} {
            padding: 25px;
        }
        i{
            font-size: 120px;
            position: absolute;
            top: 0;
            z-index: -1;
            top: 20%;
            left: 5%;
            opacity: .3;
        }
        p.disc{
            font-size: 24px;
            line-height: 40px;
            color: #fff;
            font-weight: 400;
            margin-bottom: 25px;
            @media #{$large-mobile} {
                font-size: 20px;
                line-height: 33px;
                font-weight: 300;
            }
        }
        .author{
            position: relative;
            padding-left: 60px;
            position: relative;
            display: block;
            color: var(--color-primary);
            &::after{
                position: absolute;
                content: '';
                left: 0;
                top: 47%;
                height: 2px;
                width: 40px;
                background: var(--color-primary);
                z-index: 10;
            }
        }
    }
}

.short-feature-blog-details{
    margin-bottom: 25px;
    .single-feature{
        position: relative;
        padding-left: 20px;
        margin: 7px 0;
        &::after{
            position: absolute;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            content: '';
            background: #6A6C71;
            left: 0;
            top: 40%;
        }
    }
}

.thumbnail-50{
    @media #{$large-mobile} {
        width: 100%;
        img{
            width: 100%;
        }
    }
}

// blog-details bottom
.blog-details-bottom-area{
    .tag-socila-area-blgo-details{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: flex-start;
            gap: 25px;
        }
        .left-tag{
            display: flex;
            align-items: center;
            gap: 12px;
            span{
                font-size: 20px;
                font-weight: 500;
                color: #03041C;
            }
            .tag-wrapper{
                display: flex;
                align-items: center;
                gap: 15px;
                .signle-wrapper{
                    border: 1px solid #dddddd;
                    padding: 6px 15px;
                    border-radius: 6px;
                    transition: .3s;
                    cursor: pointer;
                    &:hover{
                        background: var(--color-primary);
                        color: #fff;
                        border-color: #fff;
                    }
                }
            }
        }
    }
}
.social-blog-tag-area{
    display: flex;
    align-items: center;
    gap: 15px;
    span{
        font-size: 20px;
        font-weight: 500;
        color: #03041C;
    }
    ul{
        display: flex;
        align-items: center;
        padding: 0;
        gap: 12px;
        li{
            a{
                height: 40px;
                width: 40px;
                background: #03041C;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .3s;
                i{
                    transition: .3s;
                }
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                    transform: scale(1.05);
                    i{
                        transform: scale(.8);
                    }
                }
            }
        }
    }
}


.author-comment-area{
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 40px;
    margin-top: 50px;
    background: #dedede42;
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }
    .thumbnail{
        display: block;
        min-width: max-content;
        img{
            min-width: max-content;
            display: block;
        }
    }
    .main-information-of-autonr{
        .name{
            margin-bottom: 10px;
            font-weight: 800;
            font-size: 20px;
        }
        p.disc{
            margin-bottom: 6px;
        }
    }
}

.single-comment-audience{
    display: flex;
    align-items: flex-start;
    gap: 30px;
    margin-top: 50px;
    @media #{$small-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
    .right-area-commnet{
        width: 100%;
        .top-area-comment{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .left{
                .title{
                    margin-bottom: 5px;
                    font-size: 20px;
                }
                span{
                    color: var(--color-primary);
                }
            }
            button{
                max-width: max-content;
                margin-left: auto;
                padding: 6px 13px;
                color: #fff;
                background: #03041C;
                border-radius: 5px;
                display: flex;
                align-items: center;
                gap: 9px;
                i{
                    font-size: 14px;
                }
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                }
            }
        }
    }
}

.blog-details-form-wrapper{
    margin-top: 50px;
    .title{
        font-size: 27px;
        margin-bottom: 10px;
    }
    span{
        font-size: 16px;
    }
}

.blog-details-form{
    margin-top: 30px;
    .single-input{
        position: relative;
        i{
            position: absolute;
            right: 15px;
            top: 35%;
            transform: translateY(-50%);
            color: var(--color-primary);
        }
    }
    .half-input-wrapper{
        display: flex;
        align-items: center;
        gap: 32px;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
        }
        &>div{
            flex-basis: 48%;
            @media #{$large-mobile} {
                flex-basis: 100%;
                width: 100%;
            }
        }
    }
    input{
        height: 60px;
        border: 1px solid #BBBBBB;
        border-radius: 3px;
        margin-bottom: 30px;
        padding: 15px;
    }
    textarea{
        height: 60px;
        border: 1px solid #BBBBBB;
        border-radius: 3px;
        height: 180px;
        padding: 15px;
    }
    .tmp-btn{
        margin-top: 30px;
        display: block;
        padding: 18px 30px;
    }
}




.signle-side-bar.recent-post .body{
    @media #{$md-layout} {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        .single-blog-recent-post-sidebar{
            &:last-child{
                @media #{$md-layout} {
                    margin-bottom: 20px;
                }
            }
        }
    }
    @media #{$sm-layout} {
        .single-blog-recent-post-sidebar{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }

}


.blog-details-wrapper-v2{
    .title{
        font-size: 50px;
    }
    .thumbnail-top{
        img{
            width: 100%;
        }
    }
}