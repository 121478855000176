

.join-us-one__inner{
    padding: 80px 50px;
    background: var(--color-primary);
    overflow: hidden;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 7px;
    justify-content: space-between;
    @media #{$smlg-device} {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }
    @media #{$large-mobile} {
        padding: 20px;
    }
    .join-us-one__title-box {
        position: relative;
        display: block;
        .join-us-one__title {
            font-size: 50px;
            line-height: 1.3;
            color: #fff;
            font-weight: 700;
            letter-spacing: -1.2px;
            margin-bottom: 0;
            @media #{$sm-layout} {
                font-size: 36px;
            }
            @media #{$large-mobile} {
                font-size: 32px;
                font-weight: 500;
            }
            @media #{$small-mobile} {
                font-size: 26px;
            }
        }
    }
    .join-us-one__shape-1 {
        position: absolute;
        top: -30px;
        left: 0;
        animation: topBottom 3s ease-in-out infinite;
        z-index: -1;
    }
    .join-us-one__shape-1 img {
        width: auto;
    }
    .join-us-one__shape-2 {
        position: absolute;
        bottom: 0;
        right: 0;
        animation: leftRight 4s ease-in-out infinite;
        z-index: -1;
    }
    .join-us-one__shape-2 img {
        width: auto;
    }
    .join-us-one__form-box{
        .contact-form-2{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: 590px;
            @media #{$sm-layout} {
                width: 500px;
            }
            @media #{$large-mobile} {
                width: 280px;
                gap: 25px;
                flex-direction: column;
                gap: 25px;
            }
            button{
                position: absolute;
                max-width: max-content;
                margin-left: auto;
                right: 5px;
                @media #{$large-mobile} {
                    position: relative;
                    bottom: 0;
                    left: 0;
                    display: block;
                    right: auto;
                    margin-left: 0;
                    border: 1px solid #ebebeb;
                }
            }
            input{
                height: 55px;
                border: 1px solid #fff;
                background: #fff;
                border-radius: 5px;
            }
        }
    }
    .join-us-one__form-box{
        flex-basis: 45%;
    }
}

@keyframes topBottom {
    0% {
      transform: translateY(0px);
    }
  
    50% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes leftRight {
    0% {
      transform: translateX(0px);
    }
  
    50% {
      transform: translateX(10px);
    }
  
    100% {
      transform: translateX(0px);
    }
  }
  