/*---------------------------
 CSS Index  
****************************

01. Variables
02. Typography
03. Spacing
04. Reset
05. Forms
06. Shortcode
07. Common
08. Animations
09. Text Animation
10. Sal
11. extend
12. header-one
13. nav
14. mobile-menu
15. side-collups
15. side-collups
16.banner';
17.section-title';
18.services';
19.social';
20.button';
21.blog';
22.faq';
23.product';
24.team';
25testimonial';
26.banner';
27testimonial-swiper';
28.error';
29.img-common-style';
30.brand';
31.progress-bar';
32.icons-style';
33.portfolio';
34.pricing';
35.counter';
36.rating';
37.work';
38.taglist';
39contact';
40.tab';
41.skills';
42.quick-action';
43.breadcrumb';
44.team-details';
45.ack-to-top';
46.video';
47.pagination';
48.blog-details';
49.pre-loader';
50.about';
51.projects-details';
52.footers';
----------------------------*/



/*----Default Css----*/

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/common';
@import'default/animations';
@import'default/text-animation';
@import'default/sal';
@import'default/extend';


/*----Headers Style----*/
@import'header/header-one';
@import'header/nav';
@import'header/mobile-menu.scss';
@import'header/side-collups';


/*----Elements Style----*/
@import'elements/banner';
@import'elements/section-title';
@import'elements/services';
@import'elements/social';
@import'elements/button';
@import'elements/blog';
@import'elements/faq';
@import'elements/product';
@import'elements/team';
@import'elements/testimonial';
@import'elements/banner';
@import'elements/testimonial-swiper';
@import'elements/error';
@import'elements/img-common-style';
@import'elements/brand';
@import'elements/progress-bar';
@import'elements/icons-style';
@import'elements/portfolio';
@import'elements/pricing';
@import'elements/counter';
@import'elements/rating';
@import'elements/work';
@import'elements/taglist';
@import'elements/contact';
@import'elements/tab';
@import'elements/skills';
@import'elements/quick-action';
@import'elements/breadcrumb';
@import'elements/team-details';
@import'elements/back-to-top';
@import'elements/video';
@import'elements/pagination';
@import'elements/blog-details';
@import'elements/pre-loader';
@import'elements/language-picker';
@import'elements/mesonary';
@import'elements/working-process';
@import'elements/subscribe';

/*----Template Style----*/
@import'template/about';
@import'template/projects-details';


/*----Footer Style----*/
@import'footer/footer';
