// .container{
//     max-width: 1280px;
//     margin: 0 auto;
//     height: 100%;
//   }
  .swiper-container-style-two {
      width: 100%;
      height: 100%;
      position: relative;

  }
//   .over-flow-hidden {
//     overflow: hidden;
//   }