.about-area {

    .about-inner {
        padding-left: 80px;
        @media #{$smlg-device} {
            padding-left: 10px;
        }
        @media #{$md-layout} {
            padding-left: 15px;
            margin-top: 100px;
        }
        @media #{$sm-layout} {
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 80px;
        }
    }
    &.about-style-one {
        .about-thumbnails {
            .thumbnail {
                position: relative;
                @media #{$md-layout} {
                    min-width: max-content;
                    max-width: calc(57% + 150px);
                }
                @media #{$large-mobile} {
                    min-width: max-content;
                    max-width: calc(57% + 150px);
                }
                @media #{$small-mobile} {
                    min-width: 100%;
                    max-width: 100%;
                }
                img {
                    max-width: max-content;
                    @media #{$sm-layout} {
                        max-width: 100%;
                    }
                }
                .image-two {
                    position: absolute;
                    right: 0;
                    top: 0; 
                    @media #{$large-mobile} {
                        position: absolute;
                        right: 0;
                        top: 3%;
                        max-width: 28%;
                    }
                }
                .image-three {
                    position: absolute;
                    right: 0;
                    bottom: 80px; 
                    position: absolute;
                    // animation: bounceIn 5s linear infinite;
                    @media #{$large-mobile} {
                        max-width: 50%;
                    }
                }
                .square {
                    height: 30px;
                    width: 30px;
                    background-color: var(--color-primary);
                    position: absolute;
                    bottom: 50px;
                    right: 190px;
                }
                .flower {
                    position: absolute;
                    bottom: -35px;
                    right: 75px;
                    animation: jump-1 4s linear infinite;
                }
            }
        }
            .description {
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 20px;
            }
            .thumbnail-with-title {
                display: flex;
                align-items: center;
                width: 580px;
                margin-top: 26px;
                @media #{$smlg-device} {
                    width: auto;
                }
                @media #{$large-mobile} {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;
                }
                .thumbnail {
                    margin-right: 15px;
                    position: relative;
                    min-width: max-content;
                    img {
                         min-width: max-content;
                    }
                    .icon {
                        position: absolute;
                        height: 56px;
                        width: 56px;
                        line-height: 56px;
                        border: 2px solid var(--color-white);
                        border-radius: 100%;
                        text-align: center;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        i {
                            color: var(--color-white);
                        }
                    }
                }
                .title {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: var(--s-extra-bold);
                    font-family: var(--font-secondary);
                    color: var(--color-heading);
                    @media #{$large-mobile} {
                        font-size: 18px;
                    }
                }
            }
        
    }
    &.about-style-two{
        .about-thumbnails {
            position: relative;
            @media #{$md-layout} {
                margin: auto;
                min-width: max-content;
                max-width: max-content;
            }
            @media #{$sm-layout} {
                margin: auto;
                min-width: auto;
                max-width: max-content;
            }
            .dots {
                position: absolute;
                top: 0;
                right: 32px;
                z-index: -1;
                animation: jump-2 6s linear infinite;
            }
            .scater {
                position: absolute;
                bottom: -20px;
                left: -109px;
                z-index: -1;
                animation: jump-2 8s linear infinite;
            }
        }
        .inner {
            padding-left: 55px;
            padding-right: 0;
            @media #{$md-layout} {
                padding-left: 15px;
                margin-top: 120px;
            }
            @media #{$sm-layout} {
                padding-left: 0;
                margin-top: 70px;
            }
        }
    }
    &.about-style-three {
        .about-inner {
            .thumbnail {
                max-width: max-content;
                @media #{$sm-layout} {
                    max-width: 100%;
                    width: 100%;
                }
                img{
                    @media #{$sm-layout} {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.about-style-one{
    position: relative;
    .vedio-icone{
        position: absolute;
        top: 47%;
        left: 25%;
        @media #{$md-layout} {
            top: 19%;
        }
        @media #{$sm-layout} {
            top: 19%;
        }
        .video-play-button{
            &::after{
                background: var(--color-primary);
                border: 2px solid #fff;
                border-color: var(--color-primary);
            }
            span{
                border-left: 15px solid #fff;
            }
        }
    }
}


.product-share {
    position: absolute;
    bottom: -35px;
    left: 50px;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.0901960784);
    @media #{$smlg-device} {
        padding: 15px;
    }
    .profile-share {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            position: relative;
            z-index: 1;
            transition: .5s;
            &:nth-child(1) {
                z-index: 5;
            }
            
            &:nth-child(2) {
                margin-left: -12px;
                z-index: 4;
            }

            &:nth-child(3) {
                margin-left: -12px;
                z-index: 3;
            }
            &:nth-child(4) {
                margin-left: -12px;
                z-index: 2;
            }
            img {
               
                border-radius: 50%;
                border: 2px solid var(--color-white);
                transition: .5s;
                transform: scale(1);
                @media #{$large-mobile} {
                    max-width: 100% !important;
                }
            }

            &:hover {
                z-index: 6;
                img{
                    transform: translateY(-3px) scale(1.1);
                    border: 2px solid var(--color-primary);
                }
                
            }
        }
    }

    
}

[data-tooltip] {
    position: relative;
    font-size: 10px;
  }
  
  [data-tooltip]::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    transition: .6s;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.6s ease, visibility 0.6s ease;
  }
  
  [data-tooltip]:hover::after {
    opacity: 1;
    visibility: visible;
    bottom: 115%;
  }

//   start feature-wrapper style 

.feature-counter {
    display: flex;
    align-items: center;
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
    }
    .tmp-feature-wrapper {
        .tmp-feature {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            .feature-content {
                margin-left: 12px;
    
                .title {
                    margin-bottom: 0;
    
                }
            }
        }
    
       
    }

    .counter-wrapper {
        &.counter-style-one {
            margin-left: 75px;
            @media #{$sm-layout} {
                margin-left: 15px;
                margin-top: 30px;
            }
            .counter-inner {
                .title {
                    font-size: 70px;
                    line-height: 30px;
                    font-weight: var(--s-bold);
                    font-family: var(--font-secondary);
                    margin-bottom: 35px;
                    .counter {
                        font-size: 70px;
                        line-height: 30px;
                        margin: 0;
                        font-weight: var(--s-bold);
                        font-family: var(--font-secondary);
                    }
                }
                .description {
                    font-family: var(--font-secondary);
                    color: var(--color-heading);
                    font-weight: var(--s-medium);
                }
            }
        }
    }
}



// banner three area start
.tmp-banner-area.banner-three{
    .swiper-slide.swiper-slide-active{
        .slider-inner{
            .content{
                .title{
                    animation: fadeInUp 1.5s;
                    animation-delay: 0s;
                    display: block;
                }
                .description{
                    animation: fadeInUp 1.5s;
                    animation-delay: 0s;
                }
                .banner-bottom{
                    animation: fadeInUp 2.5s;
                    animation-delay: 0s;
                }
            }
        }
    }
}
// stroke text
.stroke__text {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: #141414;
    -webkit-text-stroke: 1px;
    font-family: var(--font-hind);

    &.v__1 {
        font-size: 96px;
        font-weight: 500;
        line-height: 80px;
    }

}

@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.tmpk__circle {
    position: absolute;
    right: 5%;
    bottom: 8%;
    svg {
        height: 176px;
        width: 176px;
        position: relative;
        padding: 8px;
        animation: rotate 10s linear infinite;
        @media #{$large-mobile} {
            height: 120px;
            width: 120px;
            padding: 2px;
        }
        text {
            textPath {
                font-size: 10.5px;
                fill: var(--color-primary);
                font-family: var(--font-secondary);
                font-weight: 500;
            }
        }
    }

    &.v__1 {
        border-radius: 50%;
        max-width: max-content;
        background: #fff;
        -webkit-box-shadow: 1px 0px 83px -43px rgba(255,0,0,0.57);
        -moz-box-shadow: 1px 0px 83px -43px rgba(255,0,0,0.57);
        box-shadow: 1px 0px 83px -43px rgba(255,0,0,0.57);
    }

    &--icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        i {
            transform: rotate(-30deg);
            color: var(--color-primary);
            font-weight: 700;
            font-size: 47px;
        }
    }

    &.v__3 {
        display: inline-block;
        position: relative;

        text {
            textPath {
                font-size: 11.5px;
                font-family: var(--font-secondary);
                font-weight: 400;
            }
        }

        .tmpk__circle--icon i {
            color: var(--color-primary);
            transform: rotate(0);
            font-size: 20px;
        }
    }
}


.about-thumbnail-construction{
    position: relative;
    z-index: 1;
    .image-large{
        img{
            width: 100%;
            border-radius: 5px;
        }
    }
    .work-done{
        position: absolute;
        right: 30px;
        bottom: 30px;
        background: var(--color-primary);
        padding: 30px;
        width: 250px;
        border-radius: 6px;
        @media #{$small-mobile} {
            padding: 12px;
        }
        .title{
            color: #fff;
            font-size: 60px;
            margin-bottom: 0;
        }
        p{
            color: #fff;
            margin: 0;
        }
    }
    .shape-bottom-left{
        position: absolute;
        height: 250px;
        width: 250px;
        background: var(--color-primary);
        z-index: -1;
        bottom: -30px;
        left: -30px;
        border-radius: 5px;
    }
}



